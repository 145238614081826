.input-select::before {
  display: none !important;
}
.input-select select {
  padding-right: 0;
}
.flatbox-imgcontainer img {
  top: 0;
  left: 0;
}
.countrybox-img img {
  transform: scale(1);
}
.landing-page select option {
  background-color: #0e939a;
  color: white;
}
